/* eslint-disable react/no-did-update-set-state */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-return-await */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import PropTypes from 'prop-types';

import PAGES_TYPE from 'shared-constants/src/pagesType';

import Error from 'shared-vectors/icons/Error';

import { isValidVar } from 'shared-utils/src/checkVarType';

import isEqual from '@lodash/isEqual';
import './critical.scss';

import PageGrid from 'shared-components/components/PageGrid';
import WatchScroll from 'shared-components/components/WatchScroll';
import sleep from 'shared-utils/src/sleep';
// import QRCodeLoader from 'shared-components/components/QRCodeTooltipFactory/QRCodeLoader';
import FloorplanOverlay from 'shared-components/components/SrpCard/components/FloorplanOverlayer';
import trackGTMEvents from 'shared-utils/src/trackGTMEvents';

import GoToTop from '@async-components/GoToTop';
import FewResultsNextPagesTitle from '@async-components/FewResultsNextPagesTitle';
import SrpMenuScrollable from '@async-components/SrpMenuScrollable';

import FilterSkeleton from '@components/searchPage/FiltersSkeleton';
import reducerSearch from '@state/reducers/search';
import selectorSearch from '@state/selectors/search';
import MandateBanner, {
  keySeen as keySeenMandate,
} from '@components/searchPage/MandateBanner/lazy';
import BannerSurvey, { keyClicked as keyClickedSurvey } from '@components/searchPage/BannerSurvey/lazy';
// import { keyClicked as keyClickedSurvey } from '@components/searchPage/BannerSurvey/lazy';
import HiddenAdsFeedback from '@components/searchPage/HiddenAdsFeedback/lazy';

import { getItem } from '@helpers/Storage';
import { createDefaultAgencyName } from '@helpers/searchAgency/SearchAgency';

import {
  getNearByName,
  getNearByMin,
  ensureNearBy,
  getNearByVehicle,
  getVehicleArt,
  getVehicleName,
} from '@helpers/dataModels/poi';

import {
  getActiveFilters,
  getActiveFiltersCountWithPTypes,
} from '@filters/helpers';

import SynchDialog from '@async-components/SynchDialog';

import App from '../app/App';

import MetaInfo from '../components/common/metainfo/MetaInfo';
import SeoBoxes from '../components/searchPage/results/SeoBoxes';
import PublisherInfos from '../components/searchPage/PublisherInfos';
import FewResultsFillIn from '../components/searchPage/FewResultsFillIn';

import Paginator from '../components/searchPage/results/Paginator';
import PublisherPaginator from '../components/searchPage/results/PublisherPaginator';
import Heading from '../components/searchPage/Heading';
import NoResultsBanner from '../components/searchPage/NoResultsBanner';
import PublisherHeading from '../components/searchPage/Heading/publisher';
import Menu from '../components/searchPage/Menu';
import SidebarBanner from '../components/searchPage/SidebarBanner';
import SidebarRiz from '../components/searchPage/SidebarBanner/Riz';
import SidebarMandate from '../components/searchPage/SidebarBanner/Mandate';
import DownloadAppBannerFixed from '../components/searchPage/DownloadAppBannerFixed/index';
import LoadSaveSearchModal from '../components/searchPage/LoadSaveSearchModal';

import ResultList from './ResultList';

import { scrollToTop } from '../helpers/DOMHelpers';

import {
  normalizeSRPUrl,
  searchPublisherParentName,
  // isBannerStickyLargeShowable,
} from '../helpers/search/Search';

import {
  checkSavedSearch,
  defaultBookmarkData,
  callGetFavorites,
  getUserHiddenAds,
  getSearchHiddenAds,
  getBookmarkAlertData,
  shouldProposeSaveSearchAfterLead,
  proposeBkmProvinces,
  getSearches,
} from '../helpers/MyCasaHelpers';

import searchGTMHelper from '../../common/analytics/searchGTMHelper';
import { search as searchTealiumHelper } from '../../common/analytics/tealium/search';

import { refreshGPTTags } from '../../common/lib/helpers/gptDataHelper';

import { getPublisherPopularLocalities } from '../components/common/search/helpers/search';

import { loadGPT } from '../helpers/AdvManager';

const propTypes = {
  device: PropTypes.string,
  mobileOs: PropTypes.string,
  orientation: PropTypes.string,
  isLocalStorageAvailable: PropTypes.bool,
  isShortDesktop: PropTypes.bool,
  actions: PropTypes.instanceOf(Object),
  viewport: PropTypes.instanceOf(Object),
  config: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
  intl: PropTypes.instanceOf(Object).isRequired,
  search: PropTypes.instanceOf(Object),
  location: PropTypes.instanceOf(Object),
  user: PropTypes.instanceOf(Object),
  seoData: PropTypes.instanceOf(Object),
  modal: PropTypes.bool,
  userLogIn: PropTypes.func,
  setFloorplanOpened: PropTypes.func,
};
const defaultProps = {
  device: '',
  mobileOs: '',
  orientation: '',
  isLocalStorageAvailable: false,
  isShortDesktop: false,
  actions: {},
  viewport: {},
  config: {},
  history: {},
  search: {},
  location: {},
  user: {},
  seoData: {},
  modal: false,
  userLogIn: () => { },
  setFloorplanOpened: () => { },
};
const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    const agencyFreetext = createDefaultAgencyName(props.search.agencyFilters);

    this.state = {
      showFilters: false, // This one is used for the initial render of filters
      mobileSearchSubmit: false,
      searchIsSaved: false,
      firstCallForSaveSearchFinished: false, // used to know if the first call for save search is finished (is finished also if the call is not needed)
      bookmarkData: defaultBookmarkData,
      availablePoi: props.search.availablePoi,
      currentUrl: props.search.uri,
      favorites: [],
      mapLinkAvailable: props.search.isMapLinkAvailable,
      availableResults: props.search.availableResults || '0',
      compactMandateBanner: false,
      compactSvSrpBanner: false,
      compactBanner: false,
      config: props.config,
      lazyAdv: true,
      publisherPopularLocations: null,
      publisherTotalLocations: 0,
      agencyFreetext,
      gtpLoaded: false,
      saveSearchBannerVisible: false,
      valutaTuVisible: false,
      hiddenAds: 0,
      // downloadAppBannerStickyLargeVisible: false,
      apiReqMyCasaBKM: {},
      dAppBnrClosed: false,
      dlVariantTestValue: null,
      showSynch: false,
      synching: false,
      afterSynchFn: null,
      searchHash: null,
      proposeBkmBaloon: props.search.proposeBkmBanner,
      showSaveSearchModalFromExternal: props.search.showSaveSearchModalFromExternal,
      showSaveSearchBannerGreenHint: false,
      showTitleFewerResults: false,
      heigthStartFewResults: null,
      cardFloorPlan: {
        showFloorplan: false,
        listingId: 0,
        floorplans: [],
        title: '',
        publisher: {},
      },
    };

    this.rizCondition = false;
    this.backButtonClicked = false;
  }


  componentWillMount() {
    const { locations, filters } = this.props;
    const levels = locations.map((el) => el.level);
    this.rizCondition =
      levels.indexOf(10) > -1 ||
      levels.indexOf(9) > -1 ||
      levels.indexOf(6) > -1;
    if ('pId' in filters) {
      this.rizCondition = false;
    }
  }

  browserBackButton = () => {
    this.backButtonClicked = true;
    const { browserBackButton } = this.props;
    browserBackButton();
  };

  handleAppLinkResize = () => {
    if (window && document && document.documentElement) {
      if (window.innerHeight !== document.documentElement.clientHeight) {
        document.documentElement.style.setProperty(
          '--minHeight',
          `${window.innerHeight}px`,
        );
      } else {
        document.documentElement.style.setProperty(
          '--minHeight',
          `${document.documentElement.clientHeight}px`,
        );
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    const {
      device,
      search,
      isLocalStorageAvailable,
      userSortedNewAds,
    } = nextProps;

    this.updateActiveNearby({
      reverseGeocodeLocation: search.reverseGeocodeLocation,
      nearby: search.clientFilters.filters.nearby,
    });

    if (device !== 'desktop') {
      this.handleAppLinkResize();
    }

    const isFetching = !nextProps.search.isFetching;
    if (isFetching) {
      if (nextProps.search.uri !== this.props.search.uri) {
        if (!nextProps?.search?.isAgencySrp) {
          refreshGPTTags(nextProps.search, nextProps.config);
          const gtm = searchGTMHelper({
            search: nextProps.search,
            variantTestValue: this.state.dlVariantTestValue || null,
          });
          const teaDl = window.tealiumDl || {};
          const teaTag = window.utag || {};

          if (teaTag.view) {
            teaTag.view({
              ...teaDl,
              ...searchTealiumHelper(gtm, nextProps.search, true),
            });
          }
          window.dataLayer.push(gtm);
        }

        if (!this.backButtonClicked) {
          nextProps.history.push(normalizeSRPUrl(nextProps.search.uri));
        }
        this.backButtonClicked = false;

        /**
         * al cambio url, se l'utente è loggato controlliamo:
         * se la ricerca è salvata
         * se l'utente ha annunci nascosti nella ricerca corrente
         */
        if (this.state.user && this.state.user.isAuthenticated) {
          const {
            user,
            user: { discardedAds },
          } = this.state;
          const saveHistory = false;
          this.updateActiveNearby(
            {
              reverseGeocodeLocation: search.reverseGeocodeLocation,
              nearby: search.clientFilters.filters.nearby,
            },
            (activeNearby) => {
              checkSavedSearch(
                user,
                search,
                search.clientFilters.filters,
                isLocalStorageAvailable,
                saveHistory,
                activeNearby,
                (
                  searchIsSaved,
                  bookmarkData,
                  apiReqMyCasaBKM,
                  searchHash,
                  bookmarks,
                ) => {
                  getSearchHiddenAds(
                    discardedAds,
                    search.apireq,
                    search.locations,
                    (hiddenAds) => {
                      shouldProposeSaveSearchAfterLead(
                        {
                          bookmarks,
                          total: search.total,
                          activeNearby,
                          filters: nextProps.filters,
                          locations: nextProps.locations,
                        },
                        (proposeBkmAfterLead) => {
                          this.setState({
                            firstCallForSaveSearchFinished: true,
                            searchIsSaved,
                            bookmarkData,
                            hiddenAds,
                            apiReqMyCasaBKM,
                            searchHash,
                            proposeBkmAfterLead,
                          });
                        },
                      );
                    },
                  );
                },
                () => {
                  this.setState({ firstCallForSaveSearchFinished: true });
                },
              );
            },
          );
        } else {
          this.setState({ firstCallForSaveSearchFinished: true });
        }
        if (
          this.getProposeBkmBaloon()
        ) {
          this.setState({ proposeBkmBaloon: { value: 'fromProposeServer' } });
        } else if (this.state.proposeBkmBaloon) {
          this.setState({ proposeBkmBaloon: null });
        }
      }
    }

    /**
     * quando l'utente si logga o ci accorgiamo che si è appena loggato controlliamo:
     * se la ricerca è salvata,
     * se l'utente ha annunci favoriti
     * se l'utente ha annunci nascosti
     * se l'utente ha annunci nascosti nella ricerca corrente
     */
    const didUserLogin =
      !this.props.user.isAuthenticated && nextProps.user.isAuthenticated;
    if (didUserLogin) {
      const { user } = nextProps;

      this.globalReducer('nearbyList', { user }, () => {
        this.updateActiveNearby(
          {
            reverseGeocodeLocation: search.reverseGeocodeLocation,
            nearby: search.clientFilters.filters.nearby,
          },
          (activeNearby) => {
            if (isLocalStorageAvailable) {
              const saveHistory = true;
              checkSavedSearch(
                user,
                search,
                search.clientFilters.filters,
                isLocalStorageAvailable,
                saveHistory,
                activeNearby,
                (
                  searchIsSaved,
                  bookmarkData,
                  apiReqMyCasaBKM,
                  searchHash,
                  bookmarks,
                  refreshBookmarks,
                ) => {
                  callGetFavorites(user, refreshBookmarks, (favorites) => {
                    getUserHiddenAds(user, (discardedAds) => {
                      getSearchHiddenAds(
                        discardedAds,
                        search.apireq,
                        search.locations,
                        (hiddenAds) => {
                          shouldProposeSaveSearchAfterLead(
                            {
                              bookmarks,
                              total: search.total,
                              activeNearby,
                              filters: nextProps.filters,
                              locations: nextProps.locations,
                            },
                            (proposeBkmAfterLead) => {
                              this.setState(
                                {
                                  favorites,
                                  searchIsSaved,
                                  firstCallForSaveSearchFinished: true,
                                  bookmarkData,
                                  user: {
                                    ...user,
                                    discardedAds,
                                  },
                                  hiddenAds,
                                  apiReqMyCasaBKM,
                                  searchHash,
                                  proposeBkmAfterLead,
                                  synching: false,
                                },
                                () => {
                                  setTimeout(
                                    () => this.setState({ showSynch: false }),
                                    300,
                                  );
                                },
                              );
                            },
                          );
                        },
                      );
                    });
                  });
                },
                () => {
                  this.setState({ firstCallForSaveSearchFinished: true });
                },
              );
            } else {
              this.setState({ user });
            }
          },
        );
      });
    } else {
      this.setState({ firstCallForSaveSearchFinished: true });
    }

    /**
     * quando l'utente si slogga
     * ricerca e annunci non possono più essere salvati
     */
    const didUserLogOut =
      this.props.user.isAuthenticated && !nextProps.user.isAuthenticated;
    if (didUserLogOut) {
      const { user } = nextProps;
      this.setState({
        user,
        searchIsSaved: false,
        bookmarkData: defaultBookmarkData,
        searchHash: null,
        favorites: [],
        hiddenAds: 0,
        firstCallForSaveSearchFinished: true,
      });
    }

    if (window && 'origin' in window) {
      this.setState({ currentUrl: `${window.origin}${nextProps.search.uri}` });
    }

    if (
      !nextProps.search.isFetching &&
      !nextProps.search.isFetchingAvailableResult &&
      (nextProps.search.availableResults !==
        this.props.search.availableResults ||
        nextProps.search.availablePoi !== this.props.search.availablePoi)
    ) {
      this.setState({
        availableResults: nextProps.search.availableResults || '0',
        availablePoi: nextProps.search.availablePoi,
      });
    }

    if (
      'publisher' in nextProps.search &&
      !this.state.publisherPopularLocations
    ) {
      const {
        filters: { channel, propertyTypeGroup },
      } = nextProps;
      this.fetchPublisherPopularLocations(channel, propertyTypeGroup);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const device = nextProps.device !== this.props.device;
    const viewport = nextProps.viewport.width !== this.props.viewport.width;
    const modal = nextProps.modal !== this.props.modal;
    const mobileSearchSubmit =
      nextState.mobileSearchSubmit !== this.state.mobileSearchSubmit;
    const isFetching =
      nextProps.search.isFetching !== this.props.search.isFetching;
    const searchIsSaved = nextState.searchIsSaved !== this.state.searchIsSaved;
    const firstCallForSaveSearchFinished = nextState.firstCallForSaveSearchFinished !== this.state.firstCallForSaveSearchFinished;
    const userAuthentication =
      nextProps.user.isAuthenticated !== this.props.user.isAuthenticated;
    const infiniteScrollNewItems =
      nextProps.search.list.length > this.props.search.list.length;
    // const locations = !isEqual(nextState.locations, this.state.locations);
    const user = !isEqual(nextProps.user, this.props.user);
    const availableResults =
      nextState.availableResults !== this.state.availableResults;
    const showFilters = nextState.showFilters !== this.state.showFilters;
    const compactMandateBanner =
      nextState.compactMandateBanner !== this.state.compactMandateBanner;
    const compactSvSrpBanner =
      nextState.compactSvSrpBanner !== this.state.compactSvSrpBanner;
    const favorites =
      nextState.favorites.length !== this.state.favorites.length;
    const publisherPopularLocations =
      nextState.publisherPopularLocations !==
      this.state.publisherPopularLocations;
    const agencyFT = nextState.agencyFreetext !== this.state.agencyFreetext;
    const hiddenAds = nextState.hiddenAds !== this.state.hiddenAds;
    const apiReqMyCasaBKM = !isEqual(
      nextState.apiReqMyCasaBKM,
      this.state.apiReqMyCasaBKM,
    );
    // const dwnlAppBannerStickyLarge = nextState.downloadAppBannerStickyLargeVisible !== this.state.downloadAppBannerStickyLargeVisible
    const totalPages =
      nextProps.search.paginator.totalPages !==
      this.props.search.paginator.totalPages;
    const showSynch = nextState.showSynch !== this.state.showSynch;
    const waitingCMP = nextProps.waitingCMP !== this.props.waitingCMP;

    if (
      device ||
      viewport ||
      modal ||
      mobileSearchSubmit ||
      isFetching ||
      searchIsSaved ||
      firstCallForSaveSearchFinished ||
      userAuthentication ||
      infiniteScrollNewItems ||
      // || locations
      availableResults ||
      showFilters ||
      compactMandateBanner ||
      compactSvSrpBanner ||
      favorites ||
      publisherPopularLocations ||
      agencyFT ||
      // Banners
      nextState.compactBanner !== this.state.compactBanner ||
      nextState.stopScrollBanners !== this.state.stopScrollBanners ||
      nextState.bannerMandateEnabled !== this.state.bannerMandateEnabled ||
      nextState.bannerSurveyEnabled !== this.state.bannerSurveyEnabled ||
      /**
       * TECH-6211
       * su android il banner della survey appare solo se siamo sicuri che
       * il banner per il push dell'app è stato chiuso o non puà apparire
       */
      nextState.dAppBnrClosed !== this.state.dAppBnrClosed ||
      user ||
      hiddenAds ||
      apiReqMyCasaBKM ||
      totalPages ||
      showSynch ||
      waitingCMP ||
      nextState.cardFloorPlan.showFloorplan !==
      this.state.cardFloorPlan.showFloorplan ||
      this.state.showSaveSearchBannerGreenHint !==
      nextState.showSaveSearchBannerGreenHint ||
      this.state.showTitleFewerResults !== nextState.showTitleFewerResults ||
      this.state.heigthStartFewResults !== nextState.heigthStartFewResults
    ) {
      return true;
    }
    return false;
  }

  updateActiveNearby = (
    { reverseGeocodeLocation, nearby },
    callBack = null,
  ) => {
    try {
      const resolvedNearBy =
        reverseGeocodeLocation && reverseGeocodeLocation[0];
      const selectedNearBy =
        nearby &&
        ensureNearBy(nearby, this.globalSelector('nearby'), resolvedNearBy);
      const activeNearby = selectedNearBy && {
        time: getNearByMin(selectedNearBy)[0],
        name: getNearByName(selectedNearBy),
        vehicle: `${getVehicleArt(
          getNearByVehicle(selectedNearBy)[0],
        )} ${getVehicleName(getNearByVehicle(selectedNearBy)[0])}`,
      };
      this.setState({ activeNearby });
      if (callBack) {
        callBack(activeNearby);
      }
    } catch (error) {
      console.log('Search::updateActiveNearby error: ', error);
      console.log({ reverseGeocodeLocation, nearby });
    }
  };

  fetchPublisherPopularLocations = async (channel, propertyTypeGroup) => {
    let filters = '';
    if (isValidVar(channel)) {
      filters += `&channel=${channel}`;
    }
    if (isValidVar(propertyTypeGroup)) {
      if (propertyTypeGroup === 'nuove-costruzioni') {
        filters += '&property_type_group=case&building_condition=nuovo';
      } else {
        filters += `&property_type_group=${propertyTypeGroup}`;
      }
    }
    const data = await getPublisherPopularLocalities({
      publisher: this.props.search.publisher.publisherId,
      filters,
    });

    this.setState({
      publisherPopularLocations: data.locations.splice(0, 20),
      publisherTotalLocations: data.total,
    });
  };

  saveSuccess = (savedSearchId, savedSearchData) => {
    const { user } = this.props;
    const { searchHash } = this.state;
    if (searchHash !== savedSearchData.hash) {
      if (savedSearchData.isUpdate) {
        this.setState({
          searchIsSaved: false,
          bookmarkData: defaultBookmarkData,
          proposeBkmBaloon: null,
        });
      }
    } else {
      getBookmarkAlertData(
        { user, id: savedSearchId },
        null,
        null,
        null,
        (searchIsSaved, bookmarkData) => {
          this.setState({
            searchIsSaved,
            bookmarkData,
            proposeBkmBaloon: null,
          });
        },
      );
    }
  };

  showSynchingDialog = (props) => {
    const { showConsentModal = false, afterSynchFn = null } = props || {};
    if (!showConsentModal) {
      this.setState({
        showSynch: true,
        synching: true,
        afterSynchFn,
      });
    }
  };

  checkProposeBkmAfterLead = () => {
    const {
      filters,
      locations,
      search: { total },
    } = this.props;
    const { activeNearby } = this.state;

    shouldProposeSaveSearchAfterLead(
      {
        bookmarks: proposeBkmProvinces(),
        total,
        activeNearby,
        filters,
        locations,
      },
      proposeBkmAfterLead => this.setState({ proposeBkmAfterLead }),
    );
  };

  getProposeBkmBaloon = () => {
    const {
      user,
      locations,
      search: { total },
    } = this.props;
    if (total > 10) {
      if (!user.isAuthenticated) {
        return true;
      }
      const currentProvince =
        locations && locations.length && locations[0]?.levels?.L6
          ? locations[0]?.levels?.L6
          : '';
      const bookmarkedProvinces = proposeBkmProvinces().provinces;
      return !bookmarkedProvinces.includes(currentProvince);
    }
  };

  listingWithAdv = () => {
    const {
      search: { list, georeachList, isPublisherHP },
      forcedAuction,
    } = this.props;
    let listing = [...list];
    const georeachCondition = georeachList && georeachList.length > 0;
    if (list.length > 0) {
      const geoPosition = 5;
      if (list.length >= 3) {
        listing = insert(list, 3, 'stripmobile');
      }
      if (georeachCondition) {
        if (list.length >= 5) {
          listing = insert(listing, geoPosition, 'georeach_1');
        }
        if (list.length >= 5) {
          listing = insert(listing, 6, 'strip1');
        }
        if (list.length >= 9) {
          listing = insert(listing, 11, 'stripmobile2');
        }
        if (list.length >= 15 && !isPublisherHP) {
          listing = insert(listing, 12, 'strip2');
        }
      } else {
        if (list.length >= 5) {
          listing = insert(listing, 5, 'strip1');
        }
        if (list.length >= 8) {
          listing = insert(listing, 9, 'stripmobile2');
        }
        if (list.length >= 10 && !isPublisherHP) {
          listing = insert(listing, 12, 'strip2');
        }
      }
      // switch (device) {
      //   case 'smartphone':
      //   case 'smartphone landscape':
      //     if (georeachCondition) {
      //       if (list.length >= 3) {
      //         listing = insert(list, 3, 'stripmobile');
      //       }
      //       if (list.length >= 5) {
      //         listing = insert(listing, geoPosition, 'georeach_1');
      //         if (list.length >= 9) {
      //           listing = insert(listing, 11, 'stripmobile2');
      //         }
      //       }
      //     } else {
      //       if (list.length >= 3) {
      //         listing = insert(list, 3, 'stripmobile');
      //       }
      //       if (list.length >= 8) {
      //         listing = insert(listing, 9, 'stripmobile2');
      //       }
      //     }
      //     break;
      //   case 'tablet':
      //     if (georeachCondition) {
      //       if (list.length >= 5) {
      //         listing = insert(list, geoPosition, 'georeach_1');
      //       }
      //       if (list.length >= 5) {
      //         listing = insert(listing, 6, 'strip1');
      //       }
      //       if (list.length >= 15) {
      //         listing = insert(listing, 17, 'strip2');
      //       }
      //     } else {
      //       if (list.length >= 5) {
      //         listing = insert(listing, 5, 'strip1');
      //       }
      //       if (list.length >= 10) {
      //         listing = insert(listing, 12, 'strip2');
      //       }
      //     }
      //     break;
      //   default:
      //     if (georeachCondition) {
      //       if (list.length >= 5) {
      //         listing = insert(list, geoPosition, 'georeach_1');
      //       }
      //       if (list.length >= 5) {
      //         listing = insert(listing, 6, 'strip1');
      //       }
      //       if (list.length >= 15 && !isPublisherHP) {
      //         listing = insert(listing, 12, 'strip2');
      //       }
      //     } else {
      //       if (list.length >= 5) {
      //         listing = insert(listing, 5, 'strip1');
      //       }
      //       if (list.length >= 10 && !isPublisherHP) {
      //         listing = insert(listing, 12, 'strip2');
      //       }
      //     }
      // }
    }
    if (forcedAuction) {
      listing = insert(listing, 0, 'forcedAuction');
    }
    return listing;
  };

  adjustPublisherPageMap = (srpMap, id) => `${srpMap}&pId=${id}`;

  extractResultsData = () => {
    const {
      search: { publisher },
      seoData: {
        urls: { srpMap },
      },
    } = this.props;
    return {
      resultsList: this.listingWithAdv(),
      publisher: publisher || null,
      mapUrl: srpMap,
    };
  };

  /**
   * Show the save search green hint banner in srp desktop
   */
  setShowSaveSearchBannerGreenHint = () => {
    this.setState({ showSaveSearchBannerGreenHint: true });
  };

  setShowTitleFewerResults = () => {
    this.setState({ showTitleFewerResults: true });
  }

  setHeigthStartFewResults = (limit) => {
    this.setState({ heigthStartFewResults: limit });
  }

  onAfterClose = () => {
    /**
     * REMOVE TEMP DOWNLOAD APP BANNER 
     const { mobileOs, search } = this.props;
    const isBannerShowable = isBannerStickyLargeShowable();
    if (
      isBannerShowable &&
      mobileOs === 'android' &&
      search.dwnlAppLocalitiesTest
    ) {
      trackGTMEvents(
        'MigrationToApp',
        'ShowContinueInApp',
        'SaveSearch',
      );
    }
    this.setDownloadAppBannerStickyLargeVisible({ val: isBannerShowable });
     */
  };

  componentDidUpdate() {
    const { showFilters, gtpLoaded } = this.state;
    const { device } = this.props;

    if (device && !gtpLoaded) {
      this.setState({ gtpLoaded: true }, () => {
        loadGPT({
          setShowSaveSearchBannerGreenHint: this.setShowSaveSearchBannerGreenHint,
        });
      });
    }

    // Load filters if the device is not a smartphone
    // I we could actually do it only on `desktop` but
    // checking `iPad Pro` device is set as `tablet` but
    // large enough to render filters, so it's not reliable
    if (!showFilters && device !== 'smartphone') {
      this.setState({ showFilters: true });
    }
  }

  globalReducer = async (type, payload, clb) => {
    const newState = await reducerSearch(this.state, type, payload);
    this.setState(newState, clb);
    return newState;
  };

  globalSelector = (type) => selectorSearch(this.state, type);

  compactMandateBanner = () => {
    const {
      search: { isPublisherHP, isPublisherPage },
    } = this.props;
    if (
      !this.state.compactMandateBanner &&
      !isPublisherHP &&
      !isPublisherPage
    ) {
      this.setState({ compactMandateBanner: true });
    }
  };

  setCurrentFloorplan = (data) => {
    sleep(300).then(() => window.history.pushState({}, null, null));
    this.setState({
      cardFloorPlan: {
        ...data,
      },
    });
    this.props.setFloorplanOpened(true);
  };

  setSaveSearchBannerVisibile = () =>
    this.setState({ saveSearchBannerVisible: true });

  setValutaTuBannerVisible = () => this.setState({ valutaTuVisible: true });

  // setDownloadAppBannerStickyLargeVisible = ({ val = false }) => this.setState({ downloadAppBannerStickyLargeVisible: val });

  dAppBnrFixClose = () => {
    /**
     * TECH-6211
     * metodo onClose per registrare la chiusura del banner app push android
     */
    let bannerMandateEnabled = false;
    let bannerSurveyEnabled = false;

    if (!this.state.bannerMandateEnabled && !this.state.bannerSurveyEnabled) {
      const {
        device,
        search: { isAgencySrp, isPublisherHP, isPublisherPage, total },
      } = this.props;

      const hasSurvey = 1;
      // const hasSurvey = 0;

      const canHaveBanners =
        device === 'smartphone' &&
        !isAgencySrp &&
        !isPublisherHP &&
        !isPublisherPage &&
        total > 0;

      const bannerMandateSeen = getItem(keySeenMandate);
      const bannerSurveyClicked = getItem(keyClickedSurvey);

      // console.log({
      //   canHaveBanners,
      //   bannerSurveyEnabled,
      // });

      bannerMandateEnabled = canHaveBanners;
      bannerSurveyEnabled =
        canHaveBanners &&
        hasSurvey &&
        bannerMandateSeen &&
        !bannerSurveyClicked;
      if (bannerSurveyEnabled) {
        bannerMandateEnabled = false;
      }
    }

    this.setState({
      dAppBnrClosed: true,
      bAppBnrCloseCoords: window.pageYOffset,
      bannerMandateEnabled,
      bannerSurveyEnabled,
    });
  };

  ptypesSelectFromModal = (newVal) =>
    this.globalReducer('ptypesSelectFromModal', newVal);

  getSearchesList = async () => {
    if (!this.state.user) {
      const pdata = await new Promise((resolve) => {
        const int = setInterval(async () => {
          if (this.state.user) {
            clearInterval(int);
            const data = await getSearches({ user: this.state.user });
            resolve(data);
          }
        }, 100);
      });
      return pdata;
    }
    const data = await getSearches({ user: this.state.user });
    return data;
  };

  render() {
    const {
      device,
      mobileOs,
      viewport,
      orientation,
      isLocalStorageAvailable,
      config,
      config: {
        env,
        remote: { LocalitiesWithAgencyZones },
      },
      search,
      user,
      userLogIn,
      updateUser,
      updateUserFormData,
      seoData,
      filters,
      locations,
      parentLocation,
      mainLocation,
      additionalLocations,
      setFilters,
      getAvailableResults,
      setForcedAuction,
      setLocations,
      setNearby,
      fillInExpanded,
      setFillInExpanded,
      submitSearch,
      loadDetail,
      collapsePublisherInfos,
      startWithSelectedPtypes,
      setNewSearchFromSaveSearchModal,
      actions: { updateSearchPaginator },
      iosLth12,
    } = this.props;

    const {
      showFilters,
      mapLinkAvailable,

      publisherPopularLocations,
      publisherTotalLocations,
      stopScrollBanners,
      compactMandateBanner,
      bannerShown,
      compactBanner,
      searchIsSaved,
      saveSearchBannerVisible,
      valutaTuVisible,
      hiddenAds,
      user: stateUser,
      apiReqMyCasaBKM,
      availableResults,
      showSynch,
      synching,
      afterSynchFn,
      // downloadAppBannerStickyLargeVisible,
      proposeBkmBaloon,
      showSaveSearchBannerGreenHint,
      showTitleFewerResults,
      showSaveSearchModalFromExternal,
    } = this.state;
    const { resultsList, publisher, mapUrl } = this.extractResultsData();

    let safeOgDataArray = [];
    if (
      'ogData' in this.props.search &&
      typeof this.props.search.ogData !== 'undefined' &&
      this.props.search.ogData !== null &&
      this.props.search.ogData.length > 0
    ) {
      safeOgDataArray = this.props.search.ogData.slice();
      const ogTitle = `${seoData.seo.h1}${seoData.seo.h2 && seoData.seo.h2 !== '' ? ` ${seoData.seo.h2}` : ''
        }`.replace('  ', ' ');
      safeOgDataArray.push({ property: 'og:title', content: ogTitle });
    }

    const appProps = {
      ...this.props,
      globalState: this.state,
      globalReducer: this.globalReducer,
      globalSelector: this.globalSelector,
      trackEvents: trackGTMEvents,
      pageData: {
        updateUser,
        updateUserFormData,
        ogData: safeOgDataArray,
        saveBookmarkSuccess: this.saveSuccess,
        isLocalStorageAvailable,
        filtersProps: {
          device,
          orientation,
          iosLth12,
          isAgencyHome: false,
          isPublisherPage: search.isPublisherPage,
          isPublisherHP: search.isPublisherHP,
          isAgencySrp: search.isAgencySrp,

          publisherName:
            publisher && 'publisherName' in publisher
              ? publisher.publisherName
              : null,
          publisherParentName: publisher
            ? searchPublisherParentName(publisher)
            : null,
          publisherId:
            publisher && 'publisherId' in publisher
              ? publisher.publisherId
              : null,
          publisherLocs:
            publisher && 'publisherId' in publisher
              ? publisherPopularLocations
              : [],
          publisherLocsNumber:
            publisher && 'publisherId' in publisher
              ? publisherTotalLocations
              : 0,

          agencyFreetext: this.state.agencyFreetext,
          localitiesWithAgencyZones: LocalitiesWithAgencyZones,
          filters,
          locations,
          parentLocation,
          mainLocation,
          additionalLocations,
          selNearBy: filters.nearby,
          setFilters,
          setLocations,
          setCurrentNearby: setNearby,
          getAvailableResults,
          availableResults,
          submitSearch,
          ptgrpsAggregates: search.ptgrpsAggregates,
          ptypesAggregate: search.ptypesAggregate,
          getActiveFilters,
          getActiveFiltersCountWithPTypes,
          startWithSelectedPtypes,
          ptypesSelectFromModal: this.ptypesSelectFromModal,
        },
        setNewSearchFromSaveSearchModal,
        showSynchingDialog: this.showSynchingDialog,
        getSearchesList: this.getSearchesList,
      },
      pageType: PAGES_TYPE.SRP,
      isAuthenticated: user.isAuthenticated,
      userIsChecked: user.checked,
      browserBackButton: this.browserBackButton,
    };

    const disableAuctions =
      (publisher &&
        'disableAuctions' in publisher &&
        publisher.disableAuctions) ||
      false;

    // Temporary dismission of the go to srp qrcode banner
    // const showQRCodeSrp =
    //   !search.isAgencySrp &&
    //   !search.isPublisherHP &&
    //   device === 'desktop' &&
    //   search.showQRCodeSrp &&
    //   search.total > 0;

    const surveyCondition = (
      typeof this.state.bannerSurveyEnabled !== 'undefined'
      && this.state.bannerSurveyEnabled
      && this.state.bannerSurveyEnabled !== 0
      && this.state.dAppBnrClosed
    );
    const showScrollableMenu = !search.isAgencySrp && !search.isPublisherHP && device === 'desktop';

    const totalArticleCount =
      typeof search.total === 'string'
        ? parseInt(search.total.replace(/\./g, ''), 10)
        : search.total;
    const pubName = publisher?.publisherName;


    return (
      <App {...appProps}>
        <MetaInfo
          domain={this.props.search.domain}
          seoData={this.props.seoData}
          locations={this.locations}
          isPublisherHP={search.isPublisherHP}
          isPublisherPage={search.isPublisherPage}
          publisher={publisher}
          uri={search.uri}
          numResults={search.total}
          paginator={search.paginator}
          preloadImg={search.preloadImg}
        />
        {showScrollableMenu && (
          <WatchScroll>
            {scrollInfos =>
              scrollInfos.coords > 245 && (
                <SrpMenuScrollable
                  title={`${seoData.seo.h1}${filters.pId ? ` ${pubName}` : ''}`}
                  h2={seoData.seo.h2}
                  scrollInfos={scrollInfos}
                  numResults={totalArticleCount}
                  setForcedAuction={setForcedAuction}
                  userLogIn={userLogIn}
                  checkProposeBkmAfterLead={this.checkProposeBkmAfterLead}
                  publisherName={
                    publisher && publisher.publisherName
                      ? publisher.publisherName
                      : null
                  }
                />
              )
            }
          </WatchScroll>
        )}
        {(showTitleFewerResults || search.fewResults?.page > 1) && (
          <WatchScroll>
            {scrollInfos => scrollInfos.coords > 300 && (
              <FewResultsNextPagesTitle scrollInfos={scrollInfos} limitFromFather={this.state.heigthStartFewResults} />
            )}
          </WatchScroll>
        )}

        {search.isPublisherPage &&
          (search.isPublisherHP || search.hasPublisherHeading) && (
            <PublisherInfos
              device={device}
              imgServer={config.urlPicture}
              data={publisher}
              numResults={totalArticleCount}
              user={user}
              isLocalStorageAvailable={isLocalStorageAvailable}
              collapse={collapsePublisherInfos}
            />
          )}
        <PageGrid
          sidebar={
            <>
              <FilterSkeleton showFilters={showFilters} />
              <SidebarBanner show={!search.isAgencySrp}>
                <SidebarMandate />
              </SidebarBanner>
              <SidebarBanner show={!search.isAgencySrp && this.rizCondition}>
                <SidebarRiz />
              </SidebarBanner>
              <div className="advElement mt--ml" id="halfpage1" />
            </>
          }
          contClassName={search.isFetching ? 'fetching' : ''}
        >
          {/* {showQRCodeSrp ? (
            <div className="qrcode-cnt">
              <QRCodeLoader
                siteUrl={config.siteUrl}
                serverUri={this.props.search.uri}
                showQRCodeSrp={showQRCodeSrp}
                sharedQRCodeGenerator={sharedQRCodeGenerator}
              />
            </div>
          ) : null} */}
          {search.isPublisherHP ? (
            <PublisherHeading
              device={device}
              orientation={orientation}
              publisherId={publisher ? publisher.publisherId : null}
              propertyTypes={publisher ? publisher.propertyTypes : null}
              numResults={totalArticleCount}
              srptitle={seoData.seo.h1}
              h2={`${seoData.seo.h2}`}
              isPublisherHP={search.isPublisherHP}
              breadcrumbData={search.breadcrumbData}
              userLogIn={userLogIn}
              isDesktop={device && device.indexOf('desktop') >= 0}
              siteUrl={config.siteUrl}
              filters={filters}
            />
          ) : (
            <Heading
              locations={locations}
              breadCrumbDropDownData={search.breadcrumbData}
              title={`${seoData.seo.h1}${filters.pId ? ` ${pubName}` : ''}`}
              availableResults={totalArticleCount}
              h2={`${seoData.seo.h2}`}
              aliasH2={`${seoData.seo.aliasH2}`}
              apiReqMyCasaBKM={apiReqMyCasaBKM}
              setForcedAuction={setForcedAuction}
              userLogIn={userLogIn}
              checkProposeBkmAfterLead={this.checkProposeBkmAfterLead}
              filters={filters}
              publisherName={
                publisher && publisher.publisherName
                  ? publisher.publisherName
                  : null
              }
              updateFilters={setFilters}
              showSaveSearchButton={!search.isAgencySrp}
              showSaveSearchBannerGreenHint={
                showSaveSearchBannerGreenHint && !search.isAgencySrp && !filters.pId
              }
            />
          )}
          <Menu
            viewport={viewport}
            siteUrl={config.siteUrl}
            accountUrl={config.api.account}
            authBasePrefix={config.api.authBasePrefix}
            mapUrl={mapUrl}
            mapLinkAvailable={mapLinkAvailable}
            apiReqMyCasaBKM={apiReqMyCasaBKM}
            isLocalStorageAvailable={isLocalStorageAvailable}
            userLogIn={userLogIn}
            afterClose={this.onAfterClose}
            setForcedAuction={setForcedAuction}
            checkProposeBkmAfterLead={this.checkProposeBkmAfterLead}
            proposeBkmBaloon={proposeBkmBaloon}
            searchIsSaved={searchIsSaved}
          />
          <NoResultsBanner
            checkProposeBkmAfterLead={this.checkProposeBkmAfterLead}
            setForcedAuction={setForcedAuction}
          />
          {disableAuctions && (
            <div className="auctions_disabled">
              <Error className="auctions_disabled_alert" />
              <p className="auctions_disabled_p">
                Le aste sono temporaneamente sospese. Salva gli immobili tra i{' '}
                <strong className="auctions_disabled_p_b">Preferiti</strong> per
                contattare chi gestisce l'annuncio successivamente.
              </p>
            </div>
          )}
          <ResultList
            resultsList={resultsList}
            search={search}
            compactMandateBanner={this.compactMandateBanner}
            setSaveSearchBannerVisibile={this.setSaveSearchBannerVisibile}
            checkProposeBkmAfterLead={this.checkProposeBkmAfterLead}
            setValutaTuBannerVisible={this.setValutaTuBannerVisible}
            saveSearchBannerVisible={saveSearchBannerVisible}
            isLocalStorageAvailable={isLocalStorageAvailable}
            user={user}
            mobileOs={mobileOs}
            searchIsSaved={searchIsSaved}
            setForcedAuction={setForcedAuction}
            valutaTuVisible={valutaTuVisible}
            device={device}
            loadDetail={loadDetail}
            viewport={viewport}
            filters={filters}
            fillInExpanded={fillInExpanded}
            setCurrentFloorplan={this.setCurrentFloorplan}
            env={env}
          />
          {search.fewResults && !search.isAgencySrp && !search.isPublisherPage && (
            <>
              <FewResultsFillIn
                setHeigthStartFewResults={this.setHeigthStartFewResults}
                loadDetail={loadDetail}
                updateSearchPaginator={updateSearchPaginator}
                collapseMandateBanner={this.compactMandateBanner}
                fillInExpanded={fillInExpanded}
                setFillInExpanded={setFillInExpanded}
                viewport={viewport}
                sortType={filters.sortType}
                setCurrentFloorplan={this.setCurrentFloorplan}
                searchIsSaved={searchIsSaved}
                setShowTitleFewerResults={this.setShowTitleFewerResults}
              />
            </>
          )}
          <Paginator />
          {search.isPublisherHP && publisher && search.total > 0 && (
            <PublisherPaginator
              listLength={search.list.length}
              numResults={search.total}
              publisherId={
                publisher && 'publisherId' in publisher
                  ? publisher.publisherId
                  : null
              }
            />
          )}
        </PageGrid>

        {!search.isAgencySrp && !search.isPublisherPage && (
          <SeoBoxes
            device={device}
            SEOLinks={search.SEOLinks}
            locations={locations}
            ptypes={filters.propertyTypes}
            numRoomsRange={{
              min: filters.numRoomsMin,
              max: filters.numRoomsMax,
            }}
          />
        )}
        {showFilters && device !== 'smartphone' && (
          <GoToTop action={scrollToTop} vh={viewport?.height || 0} />
        )}
        {device === 'smartphone' && (
          <DownloadAppBannerFixed
            currentPage={search.currentPage}
            onClose={this.dAppBnrFixClose}
          />
        )}
        {this.state.bannerMandateEnabled && (
          <WatchScroll>
            {(scrollInfos) => {
              if (scrollInfos.coords > 60 || bannerShown) {
                setTimeout(() =>
                  this.setState({
                    bannerShown: true,
                    stopScrollBanners: compactMandateBanner,
                  }),
                );
                return (
                  <MandateBanner
                    compact={compactMandateBanner || search.currentPage > 1}
                  />
                );
              }
              return null;
            }}
          </WatchScroll>
        )}
        {/* 
            REMOVE TEMP DOWNLOAD APP BANNER STICKY TECH-6058
          {
            mobileOs === 'android' &&
            search.dwnlAppLocalitiesTest &&
            downloadAppBannerStickyLargeVisible
            && <DownloadAppBannerStickyLarge
              isBannerShowable={downloadAppBannerStickyLargeVisible}
              setBannerShowable={this.setDownloadAppBannerStickyLargeVisible}
            />

          } 
        */}

        {
          surveyCondition
            ? (
              <WatchScroll stopWatching={stopScrollBanners}>
                {({ coords }) => {
                  const showme = this.state.dAppBnrClosed;
                  if (showme || bannerShown) {
                    const coordsForCompact = this.state.bAppBnrCloseCoords + 1600;                    
                    setTimeout(() => this.setState({ bannerShown: true, stopScrollBanners: compactBanner, compactBanner: compactBanner || coords > coordsForCompact }));
                    return <BannerSurvey compact={compactBanner} link="https://it.surveymonkey.com/r/LC2GVKC" title="La casa che immagino" subtitle="Partecipa alla nostra ricerca" />;
                  }
                  return null;
                }}
              </WatchScroll>
            ) : null
        }
        {
          showSaveSearchModalFromExternal && (
            <LoadSaveSearchModal
              userLogIn={userLogIn}
              setForcedAuction={setForcedAuction}
              showSaveSearchModalFromExternal={showSaveSearchModalFromExternal}
            />
          )
        }
        {this.state.cardFloorPlan.showFloorplan && (
          <FloorplanOverlay
            modalData={{}}
            floorplans={this.state.cardFloorPlan.floorplans}
            current={this.state.cardFloorPlan.current}
            setSlider={this.state.cardFloorPlan.setSlider}
            setCurrentFloorplan={this.setCurrentFloorplan}
            coordsOnClick={window?.scrollY}
            sendMail={this.state.cardFloorPlan.sendMail}
            title={this.state.cardFloorPlan.title}
            uri={this.state.cardFloorPlan.uri}
            priceEl={this.state.cardFloorPlan.priceEl}
            loadDetail={loadDetail}
            device={device}
          />
        )}
        {// (stateUser && !!stateUser.discardedAds.length > 0)
          !search.isAgencySrp && !search.isPublisherHP && stateUser && !!stateUser.discardedAds && (
            <HiddenAdsFeedback hiddenAds={hiddenAds} />
          )}
        {showSynch && (
          <SynchDialog shouldClose={!synching} afterSynch={afterSynchFn} />
        )}
      </App>
    );
  }
}
SearchPage.propTypes = propTypes;
SearchPage.defaultProps = defaultProps;
export default SearchPage;
